import './App.css';
import NavBar from './components/NavBar';
import React from 'react';
import { Box } from '@mui/material';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { Security, LoginCallback } from '@okta/okta-react';
import SecureRoute from './components/SecureRoute';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import Main from './Dashboard/Main';
import AppBar from '@mui/material/AppBar';
import Footer from './components/Footer';
import SiliconProvenance from './Dashboard/SiliconProvenance';


const okta_issuer = process.env.REACT_APP_OKTA_ISSUER;
const okta_client_id = process.env.REACT_APP_OKTA_CLIENTID;

const oktaAuth = new OktaAuth({
  issuer: okta_issuer,
  clientId: okta_client_id,
  redirectUri: window.location.origin + '/authorization-code/callback',
  scopes: ['profile', 'openid', 'groups', 'email']
});

function App() {
  const navigate = useNavigate();

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  const location = useLocation();
  let title;
  switch (location.pathname) {
    case '/':
      title = 'MI300 Series Device Lookup';
      break;
    case '/demo':
      title = 'Device Provenance (INTERNAL DEMO)';
      break;
    default:
      title = 'MI300 Series Device Lookup';
  }
  

  // Styling
  const HEADER_HEIGHT = 90;

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh', 
        }}
      >
        <AppBar
          position="fixed"
          sx={{
            boxShadow: 'none',
            backdropFilter: 'blur(6px)',
            WebkitBackdropFilter: 'blur(6px)',
            backgroundColor: 'transparent',
            height: HEADER_HEIGHT,
            width: '100%',
          }}
        >
           <NavBar title={title} />
        </AppBar>
        <Box
          component="main"
          sx={{
            flexGrow: 1, 
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Routes>
            <Route path="/authorization-code/callback" element={<LoginCallback />} />
            <Route element={<SecureRoute />}>
              <Route path="/" element={<Main />} />
              <Route path="/demo" element={<SiliconProvenance />} />
            </Route>
          </Routes>
        </Box>
        <Footer title={title} />
      </Box>
    </Security>
  );
}

export default App;
